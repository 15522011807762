import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  LegendColorBox,
  LegendContainer,
  LegendText,
} from "./AlarmDeskTableGrid.styled";

const AlarmDeskTableLegends = () => {
  const { t } = useTranslation();

  return (
    <LegendContainer>
      <Box py={1.5} px={2}>
        <Box sx={{ display: "flex", gap: "24px", alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <LegendColorBox
              backgroundColor={"#f0f6ff"}
              borderColor={"#A4C8FF"}
            ></LegendColorBox>
            <LegendText>{t("alarmDesk.legends.awayModeIsEnabled")}</LegendText>
          </Box>
          <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <LegendColorBox
              borderColor={"#FACA82"}
              backgroundColor={"#FCF8F0"}
            ></LegendColorBox>
            <LegendText>{t("alarmDesk.legends.scmAlarm")}</LegendText>
          </Box>
          <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <LegendColorBox
              borderColor={"#FA8282"}
              backgroundColor={"#FFF5F5"}
            ></LegendColorBox>
            <LegendText>
              {t("alarmDesk.legends.theAlarmIsOpenedBySomeoneElse")}
            </LegendText>
          </Box>
        </Box>
      </Box>
    </LegendContainer>
  );
};

export default AlarmDeskTableLegends;
