import React, { FC, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "app/integrations/azure/authConfig";
import { PageLoader } from "@vilocnv/allsetra-core";
import { InteractionStatus } from "@azure/msal-browser";

const Auth: FC = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (
      !isAuthenticated &&
      inProgress === InteractionStatus.None &&
      accounts.length === 0
    ) {
      instance.loginRedirect(loginRequest);
    }
  }, [isAuthenticated, inProgress, accounts]);

  return <PageLoader sx={{ width: "100dvw" }}></PageLoader>;
};

export default Auth;
