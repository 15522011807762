import { FC, Fragment, useState } from "react";
import { types, PageLoader, Button } from "@vilocnv/allsetra-core";
import {
  AlarmCardContainer,
  CommandItem,
  CommandsBox,
  Switch,
  SwitchLabel,
  ToggleFieldWrapper,
} from "./AlarmExpendableRowCard.styled";

// DATA
import AlarmActions, { AlarmActionsProps } from "./children/AlarmActions";
import AlarmMetadata from "./children/AlarmMetadata";
import AlarmComments from "./children/AlarmComments";
import { Box, useTheme } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  deviceEmergencyModeCommandThunk,
  devicePollCommandThunk,
  deviceResetGPSThunk,
  deviceResetThunk,
  setAwayModeThunk,
} from "app/features";
import { useAppSelector } from "hooks";
import { selectDeviceCommandSubmitting } from "app/data/selectors";
import {
  EmergencyMode,
  PollCommand,
  ResetDevice,
  ResetGPS,
} from "assets/icons";

export interface AlarmExpendableRowCardProps extends AlarmActionsProps {
  data: types.IAlarm;
  toggleSendEmailModal: () => void;
  toggleClearAlarmModal: () => void;
  toggleReportTheftModal: () => void;
  toggleSendSMSModal: () => void;
  handleDisableImmobilizer: (deviceId: string) => void;
  immobilizerLoading?: boolean;
  hideActionsRow?: boolean;
  isLoading?: boolean;
  fetchAlarmsConfig: () => void;
}

const AlarmExpendableRowCard: FC<AlarmExpendableRowCardProps> = ({
  data,
  toggleSendEmailModal,
  toggleClearAlarmModal,
  toggleReportTheftModal,
  toggleSendSMSModal,
  handleDisableImmobilizer,
  hideActionsRow = false,
  isLoading = false,
  immobilizerLoading = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const deviceCommandSubmitting = useAppSelector(selectDeviceCommandSubmitting);

  const [showCommandList, setShowCommandList] = useState<boolean>(false);

  const handleCommand = async (type: string) => {
    setShowCommandList(false);
    switch (type) {
      case "reset device":
        // @ts-ignore
        await dispatch(deviceResetThunk(data?.device?.uniqueId));
        break;
      case "reset gps":
        // @ts-ignore
        await dispatch(deviceResetGPSThunk(data?.device?.uniqueId));
        break;
      case "poll command":
        // @ts-ignore
        await dispatch(devicePollCommandThunk(data?.device?.uniqueId));
        break;
      case "emergency mode":
        // @ts-ignore
        await dispatch(deviceEmergencyModeCommandThunk(data?.device?.uniqueId));
        break;
    }
  };

  const handleAwayMode = async () => {
    // console.log("data==", data);
    await dispatch(
      // @ts-ignore
      setAwayModeThunk({
        accountId: data?.object?.alarmOwner?.uniqueId || "",
        objectId: data?.object?.uniqueId || "",
        awayMode: data?.awayModeEnabled || false,
      })
    );
  };

  return (
    <AlarmCardContainer>
      {isLoading ? (
        <PageLoader sx={{ width: "100%", height: "16px" }} />
      ) : (
        <Fragment>
          <AlarmActions
            data={data}
            toggleSendEmailModal={toggleSendEmailModal}
            toggleClearAlarmModal={toggleClearAlarmModal}
            toggleReportTheftModal={toggleReportTheftModal}
            toggleSendSMSModal={toggleSendSMSModal}
            handleDisableImmobilizer={handleDisableImmobilizer}
            hideActionsRow={hideActionsRow}
            immobilizerLoading={immobilizerLoading}
          />

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{
              position: "relative",
            }}
            mt={2}
          >
            <ToggleFieldWrapper>
              <Switch
                value={data?.awayModeEnabled}
                onChange={() => handleAwayMode()}
              />
              <SwitchLabel>Away Mode</SwitchLabel>
            </ToggleFieldWrapper>
            <Button
              onClick={() => setShowCommandList(!showCommandList)}
              theme={theme}
              size={"small"}
              variant="outlined"
              loading={deviceCommandSubmitting}
            >
              Send command to device
              <Box ml={1} mt={0.5}>
                <ExpandMore />
              </Box>
            </Button>

            {showCommandList && (
              <CommandsBox>
                <CommandItem onClick={() => handleCommand("reset device")}>
                  <Box ml={1} mr={1.5} mt={1}>
                    <ResetDevice />
                  </Box>
                  <Box>Reset device</Box>
                </CommandItem>
                <CommandItem onClick={() => handleCommand("reset gps")}>
                  <Box ml={1} mr={2} mt={0.5}>
                    <ResetGPS />
                  </Box>
                  <Box>Reset GPS</Box>
                </CommandItem>
                <CommandItem onClick={() => handleCommand("poll command")}>
                  <Box ml={1} mr={2} mt={0.5}>
                    <PollCommand />
                  </Box>
                  <Box>Poll Command</Box>
                </CommandItem>
                <CommandItem onClick={() => handleCommand("emergency mode")}>
                  <Box ml={1} mr={1.7} mt={0.5}>
                    <EmergencyMode />
                  </Box>
                  <Box>Emergency Mode</Box>
                </CommandItem>
              </CommandsBox>
            )}
          </Box>
          <AlarmMetadata data={data} />
          <AlarmComments alarm={data} />
        </Fragment>
      )}
    </AlarmCardContainer>
  );
};

export default AlarmExpendableRowCard;
