import { FC, Fragment, useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { Topbar, PageLoader } from "@vilocnv/allsetra-core";
import ObjectDetailsHeader from "components/sections/objects/ObjectDetailsHeader/ObjectDetailsHeader";
import ObjectDetailsBody from "components/sections/objects/ObjectDetailsBody/ObjectDetailsBody";
// import ObjectDetailsTables from "components/sections/objects/ObjectDetailsTables/ObjectDetailsTables";

// Data
import { useActiveObjectById, useAppDispatch, useAppSelector } from "hooks";
import {
  selectActiveObjectAlarmsConfig,
  selectObjectSubscriptions,
} from "app/data/selectors";
import {
  getAlarmConfigByObjectId,
  getAllSubscriptionsByObjectIdThunk,
} from "app/features";

const ObjectDetails: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const initialObjectPathRef = useRef(location.state);

  const { activeObject, activeObjectLoading } = useActiveObjectById();

  const { objectSubscriptions, objectSubscriptionsLoading } = useAppSelector(
    selectObjectSubscriptions
  );

  const { alarmsConfig } = useAppSelector(selectActiveObjectAlarmsConfig);

  const handleBackNavigation = useCallback(() => {
    const basePath = "/dashboard/objects";
    const previousPath = initialObjectPathRef?.current?.from;

    if (previousPath) {
      const searchParams = new URLSearchParams(location.search);
      navigate(`${previousPath}?${searchParams.toString()}`);
    } else {
      navigate(basePath);
    }
  }, [navigate, location]);

  useEffect(() => {
    //@ts-ignore
    if (activeObject?.uniqueId) {
      dispatch(getAllSubscriptionsByObjectIdThunk(activeObject?.uniqueId));
      dispatch(
        getAlarmConfigByObjectId({
          objectId: activeObject.uniqueId,
        })
      );
    }
  }, [activeObject?.uniqueId]);

  useEffect(() => {
    if (location.state) {
      initialObjectPathRef.current = location.state;
    }
  }, []);

  return (
    <Box>
      <Topbar
        theme={theme}
        title="Object details"
        breadcrumbTitle="Objects"
        breadcrumbRedirectTo={handleBackNavigation}
        primaryButton={{
          id: "settings",
          variant: "contained",
          text: "Settings",
          disabled: activeObjectLoading,
          startIcon: <Settings />,
          onClick: () =>
            navigate(`/dashboard/objects/${activeObject?.uniqueId}/settings`),
        }}
      />
      <Box mx={4} mt={4} id="box-item">
        {activeObjectLoading || objectSubscriptionsLoading ? (
          <PageLoader />
        ) : (
          <Fragment>
            <ObjectDetailsHeader objectName={activeObject?.name || ""} />
            <ObjectDetailsBody
              activeObject={activeObject}
              objectSubscriptions={objectSubscriptions ?? []}
              alarmsConfig={alarmsConfig}
            />
            {/* <ObjectDetailsTables /> */}
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export default ObjectDetails;
