import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { getAllAlarmTypesThunk } from "app/features/alarmsConfig/alarmsConfigActions";
import { selectAlarmsConfig } from "app/data/selectors/alarmConfigSelectors";
import { Form, Formik, FormikHelpers } from "formik";
import {
  alarmConfigValidationSchema,
  formatAlarmOptionalFilters,
  getAlarmOptionalFilters,
} from "app/data/helpers";
import AlarmConfigForm from "components/common/AlarmConfigForm";
import { PageLoader } from "@vilocnv/allsetra-core";
import { selectActiveAccountState } from "app/data/selectors";
import { updateAccountThunk } from "app/features";

const AccountAlarmConfig: FC = () => {
  const dispatch = useAppDispatch();
  const { loading, alarmTypes } = useAppSelector(selectAlarmsConfig);

  // Global State
  const { activeAccount } = useAppSelector(selectActiveAccountState);

  useEffect(() => {
    dispatch(getAllAlarmTypesThunk());
  }, []);

  const saveChangesHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    try {
      formikHelpers.setSubmitting(true);
      const workingHours = {
        workingHoursSchedule: activeAccount?.workingHours,
      };
      //@ts-ignore
      const accountType = activeAccount?.accountType?.id;
      //@ts-ignore
      const accountIndustry = activeAccount?.accountIndustry?.id;
      const data: any = {
        ...activeAccount,
        alarmsConfiguration: {
          alarms: formatAlarmOptionalFilters(values.alarms),
        },
        name: activeAccount?.name,
        workingHours,
        accountType,
        accountIndustry,
        countries: activeAccount?.countries.map((item) => item?.id),
      };
      await dispatch(
        updateAccountThunk({ accountId: activeAccount?.uniqueId || "", data })
      );
      formikHelpers.setSubmitting(false);
    } catch (error: any) {
      formikHelpers.setSubmitting(false);
      console.log(error.message);
    }
  };

  const getInitialvalues = () => {
    let alarms: any;
    if (
      activeAccount?.alarmsConfiguration?.alarms &&
      activeAccount?.alarmsConfiguration?.alarms?.length > 0
    ) {
      alarms = getAlarmOptionalFilters(
        activeAccount?.alarmsConfiguration?.alarms
      );
    } else {
      alarms = alarmTypes?.map((item: any) => {
        return {
          uniqueId: item?.uniqueId,
          isSupported: false,
          isEnabled: false,
          optionalFilters: [],
        };
      });
    }
    return { alarms };
  };

  return (
    <Formik
      initialValues={getInitialvalues()}
      validationSchema={alarmConfigValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      <Form>
        {loading ? (
          <PageLoader />
        ) : (
          <AlarmConfigForm
            title={`Alarm Configuration - ${activeAccount?.name || ""}`}
            alarmTypes={alarmTypes}
          />
        )}
      </Form>
    </Formik>
  );
};

export default AccountAlarmConfig;
