import { styled, Box, Switch as MuiSwitch } from "@mui/material";

export const AlarmCardContainer = styled(Box)({
  padding: "16px",
  background: "#EEF1FF",
});

export const CommnetsBox = styled(Box)({
  margin: "16px 0px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
});

export const CommandsBox = styled(Box)({
  width: "200px",
  borderRadius: "4px",
  backgroundColor: "white",
  position: "absolute",
  zIndex: 900,
  top: "42px",
  left: 0,
  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
});

export const CommandItem = styled(Box)({
  padding: "5px 10px",
  borderBottom: "1px solid #DFE5F4",
  color: "#1D1F2B",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const Switch = styled(MuiSwitch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  border: "0.5px solid #D5D9E8",
  borderRadius: 16,
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(19px)",
      color: "#fff",
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.primary.main,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#fff",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 16,
    height: 16,
    boxShadow: "none",
    backgroundColor: "#959EB3",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: "#F9FBFF",
  },
}));
export const ToggleFieldWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
});

export const SwitchLabel = styled(Box)({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "105%",
  letterSpacing: "-0.01em",
  color: "#323946",
  marginLeft: 16,
});
