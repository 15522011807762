import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import {
  DeleteConfirmationModal,
  Table,
  useDispatchOnParams,
  types,
  utils,
  toast,
} from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAccountDevicesThunk,
  removeDeviceFromAccountThunk,
  setActiveDevice,
} from "app/features";
import { selectAccountDevices } from "app/data/selectors";
import { ALL_DEVICES_TABLE_COLUMNS } from "app/data/constants";
import { SignalRService } from "app/data/services";

interface Props {
  accountId: string | null;
}

const AccountDevicesSection: FC<Props> = ({ accountId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  // Global State
  const { accountDevices, totalRecords, deviceLoading } =
    useAppSelector(selectAccountDevices);

  // Local State
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Boolean state for DeleteConfirmationModal Modal
  const [isDeleting, setIsDeleting] = useState(false);

  useDispatchOnParams(getAccountDevicesThunk, {
    searchByField: "serialNumber",
    args: { accountId: accountId || "" },
  });

  const openDeleteConfirmationModal = (device: any) => {
    setSelectedDeviceId(device.uniqueId);
    setOpenDeleteModal(true);
  };

  const removeDeviceHandler = async () => {
    setIsDeleting(true);

    if (selectedDeviceId && accountId) {
      const { type } = await dispatch(
        removeDeviceFromAccountThunk({
          accountId,
          deviceId: selectedDeviceId,
        })
      );

      if (type === "accounts/removeDeviceFromAccountThunk/fulfilled") {
        SignalRService.hubConnection?.on("EventRaised", (event: any) => {
          if (
            event.eventName ===
            types.BackendEventsEnum.DeviceRemovedFromAccountEvent
          ) {
            setIsDeleting(false);

            toast.success("Device has been removed from the account");

            dispatch(
              getAccountDevicesThunk({
                accountId,
                params: utils.getCommonParamsForApi(),
              })
            );
          }
        });
      } else {
        toast.error("Server side error occured.");
      }
    }

    setIsDeleting(false);
    setOpenDeleteModal(false);
  };

  const handleViewDevice = (device: types.IDevice) => {
    dispatch(setActiveDevice(device));
    navigate(`/dashboard/devices/${device?.uniqueId}`, {
      state: { from: `/dashboard/account-manager/devices/${params.id}` },
    });
  };

  return (
    <Box>
      <Table
        columns={ALL_DEVICES_TABLE_COLUMNS}
        data={accountDevices}
        progressPending={deviceLoading}
        paginationTotalRows={totalRecords}
        onRowClicked={handleViewDevice}
        cellActions={[
          { name: "Remove device", onClick: openDeleteConfirmationModal },
        ]}
        searchPlaceholder="Search device"
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to remove a device from account"
        subTitle="Do you really want to remove this device from account? This process cannot be undone."
        primaryBtnProps={{ onClick: removeDeviceHandler, loading: isDeleting }}
        theme={theme}
      />
    </Box>
  );
};

export default AccountDevicesSection;
