import moment from "moment";
import "moment/locale/nl";
import { Badge, types } from "@vilocnv/allsetra-core";
import { capitalize, isEmpty, round } from "lodash";
import {
  AccountPersonIcon,
  IgnitionGreen,
  IgnitionRed,
  ImmobilizerGreen,
  ImmobilizerRed,
  WarningIcon,
} from "assets/icons";
import { Box, Stack, Typography } from "@mui/material";
import { Battery0Bar, WarningRounded } from "@mui/icons-material";
import { checkValidDate } from "./accountsConstants";
import AlarmIcon from "assets/icons/common/AlarmIcon.png";

//
// TABLE HEADERS
//
export const ALL_ALARMS_TABLE_COLUMNS = (translator: any, lang = "en") => {
  moment.locale(lang);
  return [
    {
      name: translator("alarmDesk.labels.objectName"),
      sortable: true,
      selector: (row: types.IAlarm) => (
        <>
          <div>{row?.object?.name}</div>
          <div>{`(${row?.object?.alarmOwner?.name})`}</div>
        </>
      ),
      wrap: true,
    },
    {
      name: translator("alarmDesk.labels.objectId"),
      sortable: true,
      selector: (row: types.IAlarm) => row?.aNumber || "N/A",
      wrap: true,
      maxWidth: "115px",
    },
    {
      name: translator("alarmDesk.labels.date"),
      sortable: true,
      selector: (row: types.IAlarm) => (
        <>
          <div>
            {checkValidDate(row?.created)
              ? capitalize(moment(row?.created).fromNow())
              : "N/A"}
          </div>
          <div>
            {checkValidDate(row?.created)
              ? `(${moment(row?.created).format("MMMM DD, YYYY")}) at ${moment(
                  row?.created
                ).format("HH:mm")}`
              : "N/A"}
          </div>
        </>
      ),
      minWidth: "200px",
    },
    {
      name: translator("alarmDesk.labels.alarmType"),
      sortable: true,
      // @ts-ignore
      selector: (row: types.IAlarm) => row?.alarmType?.name || "N/A",
      wrap: true,
    },
    {
      name: translator("alarmDesk.labels.objectStatus"),
      sortable: true,
      selector: (row: types.IAlarm) => {
        let hasImmobilizer, lastBatteryStatus;
        if (!isEmpty(row.object) && !isEmpty(row.object.metadata)) {
          for (let item of row.object.metadata) {
            // Field for the Immobilizer
            if (
              item.field.uniqueId === "2ff2c194-e66f-4e58-9e2e-d3ca605d1ee6"
            ) {
              hasImmobilizer = item.value;
            }

            // Field for the Last Battery Status
            if (
              item.field.uniqueId === "4a115888-3130-4a9f-abc0-0d3b85882af5"
            ) {
              lastBatteryStatus = item.value
                ? round(parseFloat(item.value), 2)
                : item.value;
            }
          }
        }

        return (
          <>
            <Box display={"flex"} mb={0.5}>
              {row?.hasScmService && (
                <Box mr={0.5}>
                  <Badge
                    textVariant="small"
                    isRotate={false}
                    icon={<WarningRounded />}
                    colorScheme={"success"}
                  >
                    SCM
                  </Badge>
                </Box>
              )}

              <Badge
                textVariant="small"
                isRotate={false}
                icon={hasImmobilizer ? <IgnitionGreen /> : <IgnitionRed />}
                colorScheme={hasImmobilizer ? "success" : "error"}
              >
                {translator(
                  `alarmDesk.labels.${hasImmobilizer ? "on" : "off"}`
                )}
              </Badge>
            </Box>

            <Box display={"flex"}>
              <Box mr={0.5}>
                <Badge
                  icon={
                    row?.ignitionStatus === 1 ? (
                      <ImmobilizerGreen />
                    ) : (
                      <ImmobilizerRed />
                    )
                  }
                  isRotate={false}
                  textVariant="small"
                  colorScheme={row?.ignitionStatus === 1 ? "success" : "error"}
                >
                  {translator(
                    `alarmDesk.labels.${
                      row?.ignitionStatus === 1 ? "on" : "off"
                    }`
                  )}
                </Badge>
              </Box>
              <Badge
                textVariant="small"
                icon={<Battery0Bar />}
                colorScheme={"info"}
              >
                {lastBatteryStatus || "N/A"}
              </Badge>
            </Box>
          </>
        );
      },
    },
  ];
};

export const ALARM_COMMENTS_TABLE_COLUMNS = (translator: any) => {
  return [
    {
      name: translator("alarmDesk.labels.commenter"),
      sortable: true,
      selector: (row: any) => (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          ml={2}
          gap={2}
        >
          {row?.isPriority && <WarningIcon />}
          {row.isAccountComment && <AccountPersonIcon width={18} />}
          <Typography variant={"body2"}>{row?.commentedBy || "N/A"}</Typography>
        </Stack>
      ),
    },
    {
      name: translator("alarmDesk.labels.date"),
      sortable: true,
      selector: (row: any) => moment(row.date).format("MMMM DD, YYYY HH:mm"),
    },
    {
      name: translator("alarmDesk.labels.note"),
      wrap: true,
      sortable: true,
      selector: (row: any) => row.note,
    },
    {
      name: translator("alarmDesk.labels.alarmType"),
      wrap: true,
      sortable: true,
      selector: (row: any) => row.alarmType?.name ?? "",
    },
  ];
};

export const ALARM_DELAY_DROPDOWN: any = [
  {
    name: "Custom",
    value: "Custom",
  },
  {
    name: "15 min",
    value: 15,
  },
  {
    name: "30 min",
    value: 30,
  },
  {
    name: "1h",
    value: 60,
  },
  {
    name: "2h",
    value: 120,
  },
  {
    name: "5h",
    value: 300,
  },
  {
    name: "12h",
    value: 720,
  },
  {
    name: "24h",
    value: 1440,
  },
];

export const ALARM_PRIORITY: any = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
  { id: 8, name: "8" },
  { id: 9, name: "9" },
];

export const LegendsList: any = [
  { text: "Ignition off", color: "#CC1010" },
  { text: "Ignition off >10km/h", color: "#E28E10" },
  { text: "Ignition on", color: "#148E20" },
  { text: "Offline", color: "#76828F" },
  { text: "Alarm", icon: AlarmIcon },
];
