import { FC, Fragment } from "react";
import { Button, types } from "@vilocnv/allsetra-core";
import { Stack, useTheme } from "@mui/material";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";

export interface AlarmActionsProps {
  data: types.IAlarm;
  toggleSendEmailModal: () => void;
  toggleClearAlarmModal: () => void;
  toggleReportTheftModal: () => void;
  toggleSendSMSModal: () => void;
  handleDisableImmobilizer: (deviceId: string) => void;
  hideActionsRow?: boolean;
  immobilizerLoading?: boolean;
}

const AlarmActions: FC<AlarmActionsProps> = ({
  data,
  toggleSendEmailModal,
  toggleClearAlarmModal,
  toggleReportTheftModal,
  toggleSendSMSModal,
  handleDisableImmobilizer,
  hideActionsRow = false,
  immobilizerLoading = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {!hideActionsRow && (
        <Stack direction={"row"} spacing={2}>
          <Button
            theme={theme}
            variant={"outlined"}
            size={"small"}
            onClick={toggleSendEmailModal}
          >
            {t("alarmDesk.labels.email")}
          </Button>
          <Button
            theme={theme}
            variant={"outlined"}
            size={"small"}
            onClick={toggleSendSMSModal}
          >
            {t("alarmDesk.labels.sms")}
          </Button>
        </Stack>
      )}
      <Stack direction={"row"} spacing={2}>
        {!hideActionsRow && (
          <Fragment>
            <Button
              theme={theme}
              variant={"contained"}
              size={"small"}
              startIcon={<NotificationsOffIcon />}
              onClick={toggleClearAlarmModal}
            >
              {t("alarmDesk.labels.clearAlarm")}
            </Button>
            <Button
              theme={theme}
              variant={"outlined"}
              color={"error"}
              size={"small"}
              startIcon={<ReportProblemIcon />}
              onClick={toggleReportTheftModal}
              onHoverBgColor={"#f2cece"}
            >
              {t("alarmDesk.labels.theftReport")}
            </Button>
          </Fragment>
        )}
        {data.hasImmobilizer && (
          <Button
            theme={theme}
            variant={"contained"}
            color={"error"}
            size={"small"}
            startIcon={immobilizerLoading ? null : <LockIcon />}
            onClick={() =>
              handleDisableImmobilizer(data.device?.uniqueId || "")
            }
            loading={immobilizerLoading}
          >
            {t("alarmDesk.labels.disableImmobilizer")}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default AlarmActions;
