import { Box, styled } from "@mui/material";

export const LegendBoxContainer = styled(Box)({
  width: "170px",
  height: "125px",
  borderRadius: "4px",
  right: 10,
  top: 60,
  backgroundColor: "white",
  position: "absolute",
  zIndex: 300,
  padding: "14px 0px",
});

export const LegendBoxRow = styled(Box)<{ isIcon: boolean }>(({ isIcon }) => ({
  display: "flex",
  flexDirection: "row",
  marginBottom: "7px",
  marginTop: isIcon ? "8px" : 0,
}));

export const LegendContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  backgroundColor: "#ffffff",
  borderTop: "1px solid #dfe5f4",
  bottom: "0",
  left: "239px",
  width: "100%",
}));

export const LegendText = styled(Box)({
  fontSize: "12px",
});

export const LegendColorBox = styled(Box)<{
  backgroundColor: string;
  borderColor: string;
}>(({ backgroundColor, borderColor }) => ({
  width: "12px",
  height: "12px",
  backgroundColor: backgroundColor,
  border: `1px solid ${borderColor}`,
  borderRadius: "2px",
}));
