import { createSlice } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import { IDeviceEvent } from "app/data/types";
import {
  getAllDevicesThunk,
  getAllSubscriptionsByDeviceIdThunk,
  getDeviceLocationHistoryThunk,
  getDevicesByQueryThunk,
  getSpecificDeviceThunk,
  disableDeviceImmobilizerThunk,
  getDeviceLocationByDateThunk,
  getMetaDataByDeviceIdThunk,
  disableDeviceAlarmThunk,
  deviceEmergencyToggleThunk,
  deviceResetGPSThunk,
  deviceUploadPLFileThunk,
  deviceConnectManufacturerThunk,
  deviceAutoCANToggleThunk,
  deviceResetThunk,
  deviceEmergencyModeCommandThunk,
  devicePollCommandThunk,
} from "./devicesActions";

export interface IDevicesState {
  loading: boolean;
  totalDevices: number | null;

  devices: Array<any>;
  deviceLocationHistory: Array<any>;
  specificDevice: types.IDevice | null;

  deviceSubscriptions: Array<any>;
  deviceSubscriptionsLoading: boolean;
  deviceCommandSubmitting: boolean;

  deviceHistoryLoading: boolean;
  deviceHistoryByDate: Array<any>;

  deviceEventsMetaDataLoading: boolean;
  deviceEventsMetaData: Array<IDeviceEvent>;
}

const initialState: IDevicesState = {
  loading: true,
  totalDevices: null,

  devices: [],
  deviceLocationHistory: [],
  specificDevice: null,

  deviceSubscriptions: [],
  deviceSubscriptionsLoading: false,
  deviceCommandSubmitting: false,

  deviceHistoryLoading: false,
  deviceHistoryByDate: [],

  deviceEventsMetaDataLoading: false,
  deviceEventsMetaData: [],
};

const DevicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    setActiveDevice: (state, action) => {
      state.specificDevice = action.payload;
    },
    resetSpecificDevice: (state) => {
      state.specificDevice = null;
    },
    appendEventsMetaData: (state, action) => {
      state.deviceEventsMetaData = [
        ...state.deviceEventsMetaData,
        action.payload,
      ];
    },
    resetdeviceHistoryData: (state) => {
      state.deviceHistoryByDate = [];
    },
  },
  extraReducers: (builder) => {
    // Get Device Location History Action Cases
    builder.addCase(getDeviceLocationHistoryThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      getDeviceLocationHistoryThunk.fulfilled,
      (state, action) => {
        state.deviceLocationHistory = action.payload || [];
        state.loading = false;
      }
    );

    builder.addCase(getDeviceLocationHistoryThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get All devices Action Cases
    builder.addCase(getAllDevicesThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllDevicesThunk.fulfilled, (state, action) => {
      state.devices = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllDevicesThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Devies By Querying Action Cases
    builder.addCase(getDevicesByQueryThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getDevicesByQueryThunk.fulfilled, (state, action) => {
      state.devices = action.payload?.results || [];
      state.totalDevices = action.payload?.rowCount || 0;
      state.loading = false;
    });

    builder.addCase(getDevicesByQueryThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Specific Device Action Cases
    builder.addCase(getSpecificDeviceThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificDeviceThunk.fulfilled, (state, action) => {
      state.specificDevice = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificDeviceThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Subscriptions of Device By ID Thunk
    builder.addCase(getAllSubscriptionsByDeviceIdThunk.pending, (state) => {
      state.deviceSubscriptionsLoading = true;
      state.deviceSubscriptions = [];
    });

    builder.addCase(
      getAllSubscriptionsByDeviceIdThunk.fulfilled,
      (state, action) => {
        state.deviceSubscriptions = action.payload || [];
        state.deviceSubscriptionsLoading = false;
      }
    );

    builder.addCase(getAllSubscriptionsByDeviceIdThunk.rejected, (state) => {
      state.deviceSubscriptionsLoading = false;
    });

    // Disable Device Alarm Thunk Action Cases
    builder.addCase(disableDeviceAlarmThunk.pending, (state) => {
      state.deviceCommandSubmitting = true;
    });

    builder.addCase(disableDeviceAlarmThunk.fulfilled, (state) => {
      state.deviceCommandSubmitting = false;
    });

    builder.addCase(disableDeviceAlarmThunk.rejected, (state) => {
      state.deviceCommandSubmitting = false;
    });

    // Device Emergency Toggle Thunk Action Cases
    builder.addCase(deviceEmergencyToggleThunk.pending, (state) => {
      state.deviceCommandSubmitting = true;
    });

    builder.addCase(deviceEmergencyToggleThunk.fulfilled, (state) => {
      state.deviceCommandSubmitting = false;
    });

    builder.addCase(deviceEmergencyToggleThunk.rejected, (state) => {
      state.deviceCommandSubmitting = false;
    });

    // deviceEmergencyModeCommandThunk Action Cases
    builder.addCase(deviceEmergencyModeCommandThunk.pending, (state) => {
      state.deviceCommandSubmitting = true;
    });

    builder.addCase(deviceEmergencyModeCommandThunk.fulfilled, (state) => {
      state.deviceCommandSubmitting = false;
    });

    builder.addCase(deviceEmergencyModeCommandThunk.rejected, (state) => {
      state.deviceCommandSubmitting = false;
    });

    // deviceResetThunk Action Cases
    builder.addCase(deviceResetThunk.pending, (state) => {
      state.deviceCommandSubmitting = true;
    });

    builder.addCase(deviceResetThunk.fulfilled, (state) => {
      state.deviceCommandSubmitting = false;
    });

    builder.addCase(deviceResetThunk.rejected, (state) => {
      state.deviceCommandSubmitting = false;
    });

    // devicePollCommandThunk Action Cases
    builder.addCase(devicePollCommandThunk.pending, (state) => {
      state.deviceCommandSubmitting = true;
    });

    builder.addCase(devicePollCommandThunk.fulfilled, (state) => {
      state.deviceCommandSubmitting = false;
    });

    builder.addCase(devicePollCommandThunk.rejected, (state) => {
      state.deviceCommandSubmitting = false;
    });

    // Device Reset GPS Thunk Action Cases
    builder.addCase(deviceResetGPSThunk.pending, (state) => {
      state.deviceCommandSubmitting = true;
    });

    builder.addCase(deviceResetGPSThunk.fulfilled, (state) => {
      state.deviceCommandSubmitting = false;
    });

    builder.addCase(deviceResetGPSThunk.rejected, (state) => {
      state.deviceCommandSubmitting = false;
    });

    // Device Upload PL File Thunk Action Cases
    builder.addCase(deviceUploadPLFileThunk.pending, (state) => {
      state.deviceCommandSubmitting = true;
    });

    builder.addCase(deviceUploadPLFileThunk.fulfilled, (state) => {
      state.deviceCommandSubmitting = false;
    });

    builder.addCase(deviceUploadPLFileThunk.rejected, (state) => {
      state.deviceCommandSubmitting = false;
    });

    // Device Connect Manufacturer Thunk Action Cases
    builder.addCase(deviceConnectManufacturerThunk.pending, (state) => {
      state.deviceCommandSubmitting = true;
    });

    builder.addCase(deviceConnectManufacturerThunk.fulfilled, (state) => {
      state.deviceCommandSubmitting = false;
    });

    builder.addCase(deviceConnectManufacturerThunk.rejected, (state) => {
      state.deviceCommandSubmitting = false;
    });

    // Device AutoCAN Toggle Thunk Action Cases
    builder.addCase(deviceAutoCANToggleThunk.pending, (state) => {
      state.deviceCommandSubmitting = true;
    });

    builder.addCase(deviceAutoCANToggleThunk.fulfilled, (state) => {
      state.deviceCommandSubmitting = false;
    });

    builder.addCase(deviceAutoCANToggleThunk.rejected, (state) => {
      state.deviceCommandSubmitting = false;
    });

    // get Device Location By Date Thunk Action Cases
    builder.addCase(getDeviceLocationByDateThunk.pending, (state) => {
      state.deviceHistoryByDate = [];
      state.deviceHistoryLoading = true;
    });

    builder.addCase(getDeviceLocationByDateThunk.fulfilled, (state, action) => {
      state.deviceHistoryLoading = false;
      state.deviceHistoryByDate = action.payload || [];
    });

    builder.addCase(getDeviceLocationByDateThunk.rejected, (state) => {
      state.deviceHistoryByDate = [];
      state.deviceHistoryLoading = false;
    });

    // get Device events metaData Thunk Action Cases
    builder.addCase(getMetaDataByDeviceIdThunk.pending, (state) => {
      state.deviceEventsMetaData = [];
      state.deviceEventsMetaDataLoading = true;
    });

    builder.addCase(getMetaDataByDeviceIdThunk.fulfilled, (state, action) => {
      state.deviceEventsMetaData = action.payload || [];
      state.deviceEventsMetaDataLoading = false;
    });

    builder.addCase(getMetaDataByDeviceIdThunk.rejected, (state) => {
      state.deviceEventsMetaData = [];
      state.deviceEventsMetaDataLoading = false;
    });
  },
});

export * from "./devicesActions";
export const {
  setActiveDevice,
  resetSpecificDevice,
  appendEventsMetaData,
  resetdeviceHistoryData,
} = DevicesSlice.actions;

export default DevicesSlice.reducer;
