import * as Yup from "yup";
import {
  IAlarmReportTheft,
  IClearAlarm,
  IAlarmSendEmail,
  IAlarmSendSMS,
} from "app/data/types";
import { types, Badge } from "@vilocnv/allsetra-core";
import { isEmpty } from "lodash";
import { DeleteSharp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { getBadgeForAlarmConfiguration } from "./objectsHelpers";

//
// ALARM DESK FORMS HELPERS
//
export const alarmReportTheftInitialValues: IAlarmReportTheft = {
  comment: "",
  handleAlarm: false,
};

export const alarmReportTheftValidationSchema: Yup.Schema<IAlarmReportTheft> =
  Yup.object({
    comment: Yup.string().trim().required().label("Comment"),
    handleAlarm: Yup.bool().required().label("Handle Alarm"),
  });

export const clearAlarmInitialValues: IClearAlarm = {
  reason: "",
  comment: "",
  isImportant: false,
  isFromAccount: false,
  delay: 0,
};

export const countryWhitelistInitialValues: any = {
  countries: [],
};

export const countryWhiteListValidationSchema: Yup.Schema<any> = Yup.object({
  countries: Yup.array()
    .of(Yup.number())
    .min(1)
    .required()
    .label("Country name"),
});

export const clearAlarmValidationSchema: Yup.Schema<IClearAlarm> = Yup.object({
  reason: Yup.string().trim().required().label("Reason"),
  comment: Yup.string().trim().required().label("Comment"),
  isImportant: Yup.bool().required().label("isImportant"),
  isFromAccount: Yup.bool().required().label("isFromAccount"),
  delay: Yup.number().label("delay"),
});

export const alarmSendEmailInitialValues: IAlarmSendEmail = {
  defaultEmails: [],
  additionalEmails: [],
  message: "",
};

export const alarmSendEmailValidationSchema: Yup.Schema = Yup.object({
  defaultEmails: Yup.array()
    .of(Yup.string())
    .min(1)
    .required()
    .label("Default email addresses"),
  additionalEmails: Yup.array()
    .of(Yup.string())
    .required()
    .label("Additional email addresses"),
  message: Yup.string().trim().required().label("Message"),
});

export const alarmSendSMSInitialValues: IAlarmSendSMS = {
  contactPersons: [],
  message: "",
};

export const alarmSendSMSValidationSchema: Yup.Schema = Yup.object({
  contactPersons: Yup.array()
    .of(Yup.string())
    .min(1)
    .required()
    .label("Default email addresses"),
  message: Yup.string().trim().required().label("Message").max(160).min(1),
});

export const alarmConfigValidationSchema: Yup.Schema = Yup.array().of(
  Yup.object({
    uniqueId: Yup.string().required().label("uniqueId"),
    isSupported: Yup.bool().label("isSupported"),
    isEnabled: Yup.bool().label("isEnabled"),
    optionalFilters: Yup.array().of(Yup.string().min(0)),
  })
);

//
// ALARM DESK METADATA KEYVALUE TABLE HELPERS
//

export const transfromWorkingHrsForTable = (object: any) => {
  if (!object) return {};
  const result: any = {};
  const data: any = object?.isWorkingHoursOverriden
    ? object?.workingHours
    : object?.alarmOwner?.workingHours;

  data?.map(
    (item: any) =>
      (result[
        DateTime.fromObject({
          weekday: item?.dayOfWeek == 0 ? "7" : item?.dayOfWeek,
        }).toFormat("cccc")
      ] = `${item.startTime} - ${item.endTime}`)
  );
  return result;
};

export const transfromAlarmConfigForTable = (activeAlarmConfig: any) => {
  if (!activeAlarmConfig?.effectiveConfiguration) return {};

  const result: any = {};
  activeAlarmConfig?.effectiveConfiguration.map(
    (item: any) =>
      (result[item?.alarmType?.name] = getBadgeForAlarmConfiguration(
        item.isEnabled,
        item.isSupported,
        item.optionalFilters
      ))
  );
  return result;
};

export const transformAlarmForObjectInfoTable = (
  alarm: types.IAlarm,
  handleDeviceClick: any,
  handleObjectClick: any,
  objectSubscriptions: any,
  translator: any
) => {
  if (!alarm) return {};
  const _objectSubscriptions: any = {};
  const fleetNumber: any = alarm?.object?.metadata.find(
    (item: any) => item?.field?.label?.toLowerCase() === "fleet number"
  );
  objectSubscriptions.map((item: any) => {
    _objectSubscriptions[translator("alarmDesk.subscriptionName")] =
      item?.subscription?.name || "";
    _objectSubscriptions[translator("alarmDesk.serviceType")] =
      item?.subscription?.service?.name || "";
  });
  if (fleetNumber?.value) {
    _objectSubscriptions[translator("alarmDesk.fleetNumber")] =
      fleetNumber?.value;
  }
  return {
    [translator("alarmDesk.objectId")]: alarm.object?.aNumber ?? "N/A",
    [translator("alarmDesk.objectName")]: (
      <span onClick={() => handleObjectClick(alarm?.object?.uniqueId)}>
        {alarm?.object?.name ?? "N/A"}
      </span>
    ),
    [translator("alarmDesk.deviceSerialNumber")]: (
      <span onClick={() => handleDeviceClick(alarm?.device?.uniqueId)}>
        {alarm?.device?.serialNumber ?? "N/A"}
      </span>
    ),
    [translator("alarmDesk.immobiliser")]: (
      <Badge colorScheme={alarm?.hasImmobilizer ? "success" : "error"}>
        {alarm?.hasImmobilizer
          ? translator("alarmDesk.labels.yes")
          : translator("alarmDesk.labels.no")}
      </Badge>
    ),
    [translator("alarmDesk.awayMode")]: (
      <Badge colorScheme={alarm?.awayModeEnabled ? "success" : "error"}>
        {alarm?.awayModeEnabled
          ? translator("alarmDesk.labels.yes")
          : translator("alarmDesk.labels.no")}
      </Badge>
    ),
    ..._objectSubscriptions,
  };
};

export const transformAlarmPersonsForTable = (
  alarmPersons: Array<any>,
  onIconClick: any,
  alarmPersonIndex: number | null,
  alarmPersonQsData: any,
  alarmPersonQsLoading: boolean
) => {
  if (isEmpty(alarmPersons)) return {};
  const data: any = {};

  alarmPersons.forEach((person, index) => {
    data[`${index + 1} - ${person.firstName} ${person.lastName}`] = (
      <Link
        target={"_blank"}
        onClick={() => onIconClick(person, index)}
        to={`tel:${person.phone}`}
      >
        {person?.phone || "N/A"}
      </Link>
    );
    if (alarmPersonIndex === index && !alarmPersonQsLoading) {
      data[`${alarmPersonQsData?.question || "N/A"}`] = `${
        alarmPersonQsData?.answer || "N/A"
      }`;
    }
  });

  return data;
};

export const transformOwnerForCompanyInformation = (
  object: types.IObject,
  translator: any
) => {
  //@ts-ignore
  const { visitingAddress } = object?.alarmOwner;
  const address = `${visitingAddress?.street || "N/A"} ${
    visitingAddress?.houseNumber || "N/A"
  }, ${visitingAddress?.city || "N/A"}`;

  const data: any = {
    [translator("alarmDesk.companyName")]: object?.alarmOwner?.name || "N/A",
    [translator("alarmDesk.address")]: address,
    [translator("alarmDesk.status")]: (
      <Badge
        colorScheme={
          object.alarmOwner?.status === "ACTIVE" ? "success" : "error"
        }
      >
        {object.alarmOwner?.status || "N/A"}
      </Badge>
    ),
  };

  return data;
};

export const transformOwnerCountriesForWhitelisted = (
  object: types.IObject,
  onClick: any
) => {
  if (isEmpty(object)) return {};

  const data: any = {};
  const ids: any = [];

  object.alarmOwner?.countries?.map((countryWhitelist: any) => {
    data[countryWhitelist.name] = (
      <div onClick={() => onClick(countryWhitelist.name)}>
        <DeleteSharp sx={{ width: "18px", height: "18px" }} />
      </div>
    );
    ids.push(countryWhitelist.id);
  });

  return { data, ids };
};

////                                                                         ////
//// ************************* PDF REPORT THEFT HELPERS ******************** ////
////                                                                         ////

export const transformAlarmForPDFGenralDataTable = (alarm: any) => {
  if (!alarm) return {};

  const { alarmOwner } = alarm?.object;
  const { visitingAddress } = alarmOwner;
  return {
    "ID-nummer": alarm?.aNumber ?? "N/A",
    Bedrijfsnaam: alarmOwner?.name ?? "N/A",
    "KvK-nummer": alarmOwner?.kvkcocNumber ?? "N/A",
    Bezoekadres: visitingAddress
      ? `${visitingAddress?.houseNumber ? visitingAddress?.houseNumber : ""}${
          visitingAddress?.street ? `, ${visitingAddress?.street}` : ""
        }${visitingAddress?.city ? `, ${visitingAddress?.city}` : ""} ${
          visitingAddress?.state ? `, ${visitingAddress?.state}` : ""
        } ${
          visitingAddress?.country?.name
            ? `, ${visitingAddress?.country?.name}`
            : ""
        }`
      : "",
    Postcode: visitingAddress?.postalCode ?? "N/A",
    Vestigingsplaats: visitingAddress?.city ?? "N/A",
    Land: visitingAddress?.country?.name ?? "N/A",
    Telefoonnummer: visitingAddress?.phoneNumber ?? "N/A",
  };
};

export const transformAlarmForPDFWarningAddressesTable = (
  alarmPersons: Array<any>
) => {
  if (isEmpty(alarmPersons)) return [];

  const data: any = {};

  alarmPersons?.forEach((person, ind) => {
    data[`Warning Address ${ind + 1}`] = {
      Voornaam: person?.firstName ?? "",
      Achternaam: person?.lastName ?? "",
      "Mobiele nummer": person?.phone ?? "",
      Emailadres: person?.email ?? "",
    };
  });

  return data;
};

export const transformAlarmForPDFEquipmentTable = (alarm: any) => {
  if (!alarm) return {};

  const { object } = alarm;

  const equipmentData: any = {};

  !isEmpty(object?.metadata)
    ? object?.metadata.map((meta: any) => {
        const { field } = meta;

        equipmentData[field?.label] = meta?.value;
      })
    : (equipmentData["Data"] = "No Metadata Available");

  return equipmentData;
};

export const getFullAddress = async (lat: number, long: number) => {
  return new Promise<{ street: string; city: string; country: string }>(
    (resolve) => {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = new window.google.maps.LatLng(lat, long);

      let city = "City Not Available";
      let country = "Country Not Available";
      let street = "Street Not Available";

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results && results[0]) {
            results[0].address_components.forEach((address) => {
              if (address.types.includes("locality")) {
                city = address.long_name;
              }
              if (address.types.includes("country")) {
                country = address.long_name;
              }
              if (address.types.includes("route")) {
                street = address.long_name;
              }
            });
          } else {
            console.log("Address not found");
          }
          resolve({ street, city, country });
        } else if (status === "ZERO_RESULTS") {
          console.log("No results found for provided coordinates");
          resolve({ street, city, country });
        } else {
          console.log("Geocoder failed due to: " + status);
          resolve({ street, city, country });
        }
      });
    }
  );
};

export const transformAlarmForPDFLocationNotificationTable = async (
  alarm: any
) => {
  if (!alarm) return {};

  const { street, city, country } = await getFullAddress(
    alarm?.location?.latitude ?? 0,
    alarm?.location?.longitude ?? 0
  );
  return {
    [street]: "",
    [city]: "",
    [country]: "",
    Coördinaten: alarm?.location
      ? `${alarm?.location?.latitude},${alarm.location?.longitude}`
      : "0,0",
    Tijdstip: alarm?.location?.date ?? "Not Available",
  };
};

export const transformAlarmForPDFLastGoodGpsTable = async (alarm: any) => {
  if (!alarm) return {};

  const { street, city, country } = await getFullAddress(
    alarm?.object?.location?.latitude ?? 0,
    alarm?.object?.location?.longitude ?? 0
  );

  return {
    [street]: "",
    [city]: "",
    [country]: "",
    Coördinaten: alarm?.object?.location
      ? `${alarm?.object?.location?.latitude},${alarm?.object?.location?.longitude}`
      : "0,0",
    Tijdstip: alarm?.object?.location?.date ?? "Not Available",
  };
};

export const transformAlarmForPDFMapImages = (alarm: any) => {
  if (!alarm) return {};

  const locationNotificationCoordinates = alarm?.location
    ? `${alarm?.location?.latitude},${alarm?.location?.longitude}`
    : "0,0";
  const lastGoodGpsCoordinates = alarm?.object?.location
    ? `${alarm?.object?.location?.latitude},${alarm?.object?.location?.longitude}`
    : "0,0";

  return {
    locationNotification: {
      imageUrl: "",
      location: locationNotificationCoordinates,
      zoom: "12",
      type: "roadmap",
    },

    lastGoodGpsZoomedOut: {
      imageUrl: "",
      location: lastGoodGpsCoordinates,
      zoom: "10",
      type: "roadmap",
    },

    lastGoodGpsMap: {
      imageUrl: "",
      location: lastGoodGpsCoordinates,
      zoom: "12",
      type: "roadmap",
    },

    lastGoodGpsZoomed: {
      imageUrl: "",
      location: lastGoodGpsCoordinates,
      zoom: "14",
      type: "roadmap",
    },

    lastGoodGpsSatZoomedOut: {
      imageUrl: "",
      location: lastGoodGpsCoordinates,
      zoom: "10",
      type: "hybrid",
    },

    lastGoodGpsSat: {
      imageUrl: "",
      location: lastGoodGpsCoordinates,
      zoom: "13",
      type: "hybrid",
    },
  };
};

export const getSMSModalInitialValues = (
  selectedAlarm: types.IAlarm | null
) => {
  return {
    contactPersons: [],
    message: `Van uw ${selectedAlarm?.object?.name || ""} ontvangen wij een ${
      selectedAlarm?.alarmType?.name || ""
    }. Locatie: ${selectedAlarm?.location?.resolvedAddress || "N/A"}`,
  };
};

export const getAlarmCoordsWithObjectLocation = (
  alarm: types.IAlarm
): Array<{ latitude: number; longitude: number }> => {
  const coords = [];

  if (alarm.location) {
    const alarmLocation = {
      latitude: alarm.location?.latitude,
      longitude: alarm.location?.longitude,
    };

    coords.push(alarmLocation);
  }

  if (alarm.object.location) {
    const objectLocation = {
      latitude: alarm.object.location?.latitude,
      longitude: alarm.object.location?.longitude,
    };

    coords.push(objectLocation);
  }

  return coords;
};
