import { FC, useState } from "react";
import { omit } from "lodash";
import { Box, useTheme } from "@mui/material";
import { Modal, ModalProps, types, utils, toast } from "@vilocnv/allsetra-core";
import { Formik, Form, FormikHelpers } from "formik";
import InnerForm from "./children/InnerForm";

// DATA
import { useAppDispatch, useDispatchOnMount } from "hooks";
import { IAccountAssignUser } from "app/data/types";
import {
  accountAssignUserInitialValues,
  accountAssignUserValidationSchema,
} from "app/data/helpers";
import {
  associateUserToAccountThunk,
  getAccountObjectTypesThunk,
  getAvailableKeysByAccountThunk,
  useGetAvailableUsersForAccountQuery,
} from "app/features";
import { SignalRService } from "app/data/services";

export type Props = Omit<ModalProps, "title" | "children"> & {
  accountId: string | null;
  roles: Array<any>;
};

const AssignUserForm: FC<Props> = ({ open, onClose, accountId, roles }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [submitting, setSubmitting] = useState(false);

  const { data: availableUsers } =
    useGetAvailableUsersForAccountQuery(accountId);

  useDispatchOnMount(getAvailableKeysByAccountThunk, accountId);

  const onSubmitHandler = async (
    values: IAccountAssignUser,
    formikHelpers: FormikHelpers<IAccountAssignUser>
  ) => {
    setSubmitting(true);
    formikHelpers.setSubmitting(true);

    const userUniqueId =
      availableUsers.find((user: any) => user.email === values.userEmail)
        ?.uniqueId || "";

    const { type } = await dispatch(
      associateUserToAccountThunk({
        accountId,
        userId: userUniqueId,
        data: omit(values, ["userEmail"]),
      })
    );

    if (type === "accounts/associateUserToAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.UserAssignedToAccountEvent
        ) {
          setSubmitting(false);
          formikHelpers.setSubmitting(false);
          onClose();
          formikHelpers.resetForm();

          toast.success("User has been assigned successfully.");

          dispatch(
            getAccountObjectTypesThunk({
              accountId: event.accountId,
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      toast.error("User already assigned to this account.");
      setSubmitting(false);
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={accountAssignUserInitialValues}
        validationSchema={accountAssignUserValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form>
            <Modal
              open={open}
              onClose={onClose}
              title="Assign user"
              primaryBtnProps={{
                type: "submit",
                text: "Assign user",
                loading: isSubmitting || submitting,
                disabled: !isValid,
                // @ts-ignore
                onClick: handleSubmit,
              }}
              secondaryBtnProps={{ text: "Cancel", onClick: onClose }}
              theme={theme}
            >
              <InnerForm roles={roles} accountId={accountId} />
            </Modal>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AssignUserForm;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
